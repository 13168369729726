import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Fields } from 'src/app/constants/fields';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/states/app.state';
import { hidePopupAction } from 'src/app/store/actions/popup.actions';
import { BasePageComponent } from '../../pages/base-page.component';
import { QuoteDataService } from 'src/app/services/quote-data.service';
import { EditDiscoveredDriver } from 'src/app/store/actions/quote-data.actions';
import { DriverTypeEnum } from '../../pages/auto/drivers/model/driver-source.enum';

@Component({
	selector: 'driver-discovered-popup',
	templateUrl: './driver-discovered-popup.component.html',
	styleUrls: ['./driver-discovered-popup.component.scss'],
})
export class DriverDiscoveredPopupComponent extends BasePageComponent implements OnInit {
	data: any;
	public DriverTypeEnum = DriverTypeEnum;
	constructor(injector: Injector, protected quoteDataService: QuoteDataService, protected store: Store<IAppState>) {
		super(injector, store);
	}

	ngOnInit(): void {
		super.ngOnInit();
		if (this.data) {
			this.initform(this.data);
			this.form.markAsUntouched();
			this.form.controls.DriverRelationshipToDriver1.markAsUntouched();
		}
	}

	initform(data?): void {
		this.form = new FormGroup({});

		this.form.addControl(
			Fields.MaritalStatus.name,
			new FormControl({ value: data.data.MaritalStatus, disabled: false }, [Validators.required])
		);

		this.form.addControl(
			Fields.DriverRelationshipToDriver1.name,
			new FormControl({ value: data.data.DriverRelationshipToDriver1, disabled: false }, [Validators.required])
		);

		this.form.addControl(
			Fields.DriverLicenseStatus.name,
			new FormControl({ value: data.data.DriverLicenseStatus, disabled: false }, [Validators.required])
		);
	}

	handleAddEdit() {
		let driver = {
			data: {
				...this.form.value,
				Id: this.data.data.Id,
			},
		};

		this.store.dispatch(EditDiscoveredDriver(driver));

		this.store.dispatch(hidePopupAction());
	}

	close() {
		this.store.dispatch(hidePopupAction());
	}
}
